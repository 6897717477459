// Dracula Theme Color Scheme --> https://github.com/dracula/dracula-theme/

$background-color : #282a36;
$current-line: 	#44475a;
$foreground: #f8f8f2;
$comment: #6272a4;
$purple: #bd93f9;
$cyan: #8be9fd;
$yellow: #f1fa8c;
$red: #ff5555;

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  letter-spacing: 0.03em;
  font-weight: 200;
  background-color: lightgray;
  margin: 0; }
#root {
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 20px; }

#calculator {
  background-color: $background-color;
  max-width: 600px;
  height: auto;
  display: grid;
  border-radius: 20px;
  box-shadow: 5px 15px 40px rgba(0,0,0,0.5);
  overflow: hidden; }


#display {
  display: grid;
  height: 80px;
  padding: 30px;
  text-align: right;
  margin-bottom: 5px;
  border-radius: 50px;
  #output {
    font-size: 36px;
    color: $yellow;
    align-self: end; }
  #formula {
    font-size: 18px;
    color: $comment; } }

button:focus {
  border: none;
  outline: none; }

#buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 90px);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-template-areas: "clear clear divide multiply""one two three subtract""four five six add""seven eight nine equals""zero zero decimal equals"; }

.button {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  color: $cyan;
  border: none;
  background-color: $current-line;
  padding: 0;
  focus: {
    outline: none; } }

#equals {
  grid-area: equals;
  color: $purple; }

#decimal {
  grid-area: decimal;
  color: $purple; }

#clear {
  grid-area: clear;
  color: $red; }

#divide {
  grid-area: divide;
  color: $purple; }

#multiply {
  grid-area: multiply;
  color: $purple; }

#add {
  grid-area: add;
  color: $purple; }

#subtract {
  grid-area: subtract;
  color: $purple; }

#zero {
  grid-area: zero; }

#one {
  grid-area: one; }

#two {
  grid-area: two; }

#three {
  grid-area: three; }

#four {
  grid-area: four; }

#five {
  grid-area: five; }

#six {
  grid-area: six; }

#seven {
  grid-area: seven; }

#eight {
  grid-area: eight; }

#nine {
  grid-area: nine; }

footer {
  font-size: 14px;
  max-width: 600px;
  box-sizing: border-box;
  padding: 40px;
  text-align: center;
  color: $background-color;
  a {
    color: $purple;
    text-decoration: none; } }
